import { fetchApi } from './fetch-api';
const baseUrl: string = process.env.REACT_APP_ADMIN_API_LINK as string;
interface IAdminApiReleaseNotesFetch {
  token: string | undefined;
}
export const getPublishedReleaseNotes = async (
  adminApiReleaseNotesFetch: IAdminApiReleaseNotesFetch,
) => {
  const response = await fetchApi({
    headers: {
      authorization: `Bearer ${adminApiReleaseNotesFetch.token}`,
    },
    method: 'GET',
    url: `${baseUrl}/release-notes/getPublishedReleaseNotes`,
  });
  return response;
};
