import { lazy, Suspense } from 'react';
import { Loader, Modal } from '@gitlab-rtsensing/component-library';
import { lazyImport } from '../../utils/lazy-import';
import './index.scss';
import AmgenNetworks from '../../assets/icons/amgen-networks';
import Logo from '../../assets/images/Amgen-Sensing.png';
import React from 'react';

interface RequestAccessModalProps {
  title?: string;
  opened: boolean;
  onRequestAccessModalClosed: () => void;
}

const RequestAccessForm = lazy(() =>
  lazyImport('@amgen/rtsensing-request-form'),
);

const RequestAccessModal = ({
  title = 'Request Access',
  opened,
  onRequestAccessModalClosed,
}: RequestAccessModalProps) => {
  return (
    <Modal
      className="home-page-request-access-modal"
      opened={opened}
      onClose={onRequestAccessModalClosed}
    >
      <Modal.Header
        contentClassName="d-flex align-items-center"
        className="request-modal-header"
      >
        <AmgenNetworks fill="var(--ops-white)" />
        <img src={Logo} alt="amgen logo"></img>
        <Modal.Title className="request-modal-title" title={title} />
      </Modal.Header>
      <Suspense fallback={<Loader />}>
        {opened && <RequestAccessForm />}
      </Suspense>
    </Modal>
  );
};

export default RequestAccessModal;
