import React from 'react';
import { MetricCard, Button } from '@gitlab-rtsensing/component-library';
import './index.scss';

interface RequestAccessTileProps {
  title: string;
  openRequestAccessHandler: (bool: boolean) => void;
}

const RequestAccessTile = (props: RequestAccessTileProps): JSX.Element => {
  const { title, openRequestAccessHandler } = props;

  function handleRequestAccessClick() {
    openRequestAccessHandler(true);
  }

  return (
    <>
      <MetricCard>
        <>
          <MetricCard.Header>
            <MetricCard.Title title={title} />
          </MetricCard.Header>
          <MetricCard.Content className="home-request-access-tile-content">
            <>
              <p className="home-request-access-tile-text ops-font-inter ">
                You are not authorized to view this panel. If you feel this is
                an error, you can request access.
              </p>
              <Button
                className="home-request-access-tile-button"
                onClick={handleRequestAccessClick}
                type="secondary"
                label="Request Access"
              ></Button>
            </>
          </MetricCard.Content>
        </>
      </MetricCard>
    </>
  );
};

export default RequestAccessTile;
