import axios from 'axios';
const baseUrl: string = process.env.REACT_APP_ADMIN_API_LINK as string;

export const getValueCaptureData = async (oktaToken: string | undefined) => {
    const finalUrl = `${baseUrl}/sensingAbout/getAboutValueCapture`;
    const res = await axios({
        method: 'get',
        url: finalUrl,
        headers: {
            Authorization: `Bearer ${oktaToken}`,
        },
    });
    return res;
};

export const insertUpdateValueCapture = async (oktaToken: string | undefined, payload: unknown) => {
    const finalUrl = `${baseUrl}/sensingAbout/insertUpdateAboutValueCapture`;
    const res = await axios({
        method: 'POST',
        url: finalUrl,
        headers: {
            Authorization: `Bearer ${oktaToken}`,
        },
        data: payload
    });
    return res;
};

export const deleteValueCapture = async (oktaToken: string | undefined, payload: unknown) => {
    const finalUrl = `${baseUrl}/sensingAbout/removeAboutValueCapture?id=${payload}`;
    const res = await axios({
        method: 'POST',
        url: finalUrl,
        headers: {
            Authorization: `Bearer ${oktaToken}`,
        }
    });
    return res;
};
export const getKeyHighlights = async (oktaToken: string | undefined, flag: string, year: number) => {
    const finalUrl = `${baseUrl}/sensingAbout/getKeyHighlights?flag=${flag}&year=${year}`;
    const res = await axios({
        method: 'get',
        url: finalUrl,
        headers: {
            Authorization: `Bearer ${oktaToken}`,
        },
    });
    return res;
};


export const updateKeyHighlight = async (oktaToken: string | undefined, payload: unknown) => {
    const finalUrl = `${baseUrl}/sensingAbout/updateKeyHighlights`;
    const res = await axios({
        method: 'POST',
        url: finalUrl,
        headers: {
            Authorization: `Bearer ${oktaToken}`,
        },
        data: payload
    });
    return res;
};

export const deleteKeyHighlight = async (oktaToken: string | undefined, payload: unknown) => {
    const finalUrl = `${baseUrl}/sensingAbout/removeKeyHighlights?id=${payload}`;
    const res = await axios({
        method: 'POST',
        url: finalUrl,
        headers: {
            Authorization: `Bearer ${oktaToken}`,
        }
    });
    return res;
};

export const getKeyHighlightsYear = async (oktaToken: string | undefined) => {
    const finalUrl = `${baseUrl}/sensingAbout/getHighlightsYear`;
    const res = await axios({
        method: 'get',
        url: finalUrl,
        headers: {
            Authorization: `Bearer ${oktaToken}`,
        },
    });
    return res;
};