export function convertToTitleCase(inputString: string): string {
  const words = inputString.split(' ');
  const titleCaseWords = words.map(
    word => word.charAt(0).toUpperCase() + word.slice(1),
  );
  const titleCaseString = titleCaseWords.join(' ');

  return titleCaseString;
}

export function hyphenToCamel(hyphenated: string): string {
  return hyphenated.replace(/(-\w)/g, m => m[1].toUpperCase());
}

export function camelToHyphen(camelCase: string): string {
  return camelCase.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
}
