import { useEffect } from 'react';
import { NavLinkSections, NavManager } from '@amgen/rtsensing-nav';

let navManager: NavManager | undefined = undefined;
if (NavManager) {
  navManager = new NavManager('About');
}

const aboutLinks: NavLinkSections = {
  jumpToSections: [
    {
      sectionLabel: '',
      links: [
        {
          label: 'What is Sensing?',
          url: '#intro',
          disabled: false,
        },
        {
          label: 'Vision',
          url: '#vision',
          disabled: false,
        },
        {
          label: 'What information domains are in scope for Sensing?',
          url: '#info',
          disabled: false,
        },
        {
          label: 'Is Sensing Secure?',
          url: '#security',
          disabled: false,
        },
        {
          label: 'How is the Sensing Platform structured?',
          url: '#structure',
          disabled: false,
        },
        {
          label: 'Views Available in Sensing',
          url: '#views',
          disabled: false,
        },
        {
          label: "Sensing Newsletter",
          url: '#newsletters',
          disabled: false,
        },
        {
          label: 'Release Notes',
          url: '#notes',
          disabled: false,
        },
      ],
    },
  ],
  internalSections: [],
};
const valueCaptureLinks: NavLinkSections = {
  jumpToSections: [
    {
      sectionLabel: 'Value Capture',
      links: [
        {
          label: 'Sensing Key Impact Metrics',
          url: '#keyimpactmetrics',
          disabled: false,
        },
        {
          label: 'Key Highlights',
          url: '#keyhighlights',
          disabled: false,
        },
        {
          label: 'Key AI Release Notes',
          url: '#keyquotes',
          disabled: false,
        },
        {
          label: 'Productivity and Savings',
          url: '#productivityAndsavings',
          disabled: false,
        },
      ],
    },
  ],
  internalSections: [],
};
const releaseNotesLinks: NavLinkSections = {
  jumpToSections: [],
  internalSections: [
    {
      sectionLabel: '',
      links: [
        {
          label: 'Return to About Page',
          url: '/about',
          disabled: false,
        },
      ],
    },
  ],
};

export function useAboutPageNavManager() {
  useEffect(() => {
    if (!navManager) {
      return;
    }

    navManager.setNavLinkSections(aboutLinks);
  });
}

export function useValueCaptureNavManager() {
  useEffect(() => {
    if (!navManager) {
      return;
    }

    navManager.setNavLinkSections(valueCaptureLinks);
  });
}
export function useReleaseNotesPageNavManager(
  additionalLinks: NavLinkSections,
) {
  useEffect(() => {
    if (!navManager) {
      return;
    }

    const links = {
      ...releaseNotesLinks,
      ...additionalLinks,
    };

    navManager.setNavLinkSections(links);
  });
}
