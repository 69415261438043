import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useQuery } from 'react-query';
import { postKeyEventsAndPrioritized } from '../../api/key-events-and-prioritized-agenda-api';
import {
  MetricCard,
  Button,
  ExternalLinkIcon,
  Loader,
} from '@gitlab-rtsensing/component-library';
import './index.scss';

interface ImageTileProps {
  title: string;
  params: string;
  linkURL: string;
  keyParam: string;
}

const ImageTile = (props: ImageTileProps): JSX.Element => {
  const { title, params, linkURL, keyParam } = props;
  const { authState } = useOktaAuth();
  const {
    data: prioritizedApiData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: params,
    queryFn: () =>
      postKeyEventsAndPrioritized({
        token: authState?.accessToken?.accessToken,
        parameter: params,
      }),
  });

  return (
    <MetricCard className="home-image-tile">
      <>
        <MetricCard.Header>
          <MetricCard.Title title={title} />
        </MetricCard.Header>
        <MetricCard.Content className="home-image-tile-content">
          <>
            {isLoading ? (
              <Loader />
            ) : (
              <div className="home-image-tile-image-main">
                <div className="home-image-tile-image-wrapper">
                  {isError ? (
                    <p className="home-prioritize-error">
                      Something Went Wrong!
                    </p>
                  ) : (
                    <img
                      className="home-image-tile-image"
                      src={prioritizedApiData?.data?.data[keyParam]}
                      alt={title}
                    />
                  )}
                </div>
              </div>
            )}

            {!isError && (
              <Button
                className="home-image-tile-button"
                iconPosition="right"
                onClick={() => {
                  window.open(linkURL, '_blank', 'noopener,noreferrer');
                }}
                icon={<ExternalLinkIcon />}
                type="secondary"
                label={`GO TO ${title}`}
              ></Button>
            )}
          </>
        </MetricCard.Content>
      </>
    </MetricCard>
  );
};

export default ImageTile;
