import React from 'react';
import TrendingIcon from '../../../assets/icons/trendingIcon';
import ClockIcon from '../../../assets/icons/clockIcon';
import WaveIcon from '../../../assets/icons/waveIcon';
import parse from 'html-react-parser';
import { Badge } from '@opsdti-global-component-library/amgen-design-system';

export type ListProps = {
  icon?: string;
  heading?: string;
  text?: string;
  image?: string;
  details_id?: string;
};

type List = {
  listcontent: ListProps[];
};

type ListRoot = {
  content: { list: List; content_id: string }
  height?: string;
  contentEditable?: boolean;
  onTextChange: (e: React.FocusEvent<HTMLDivElement>, val: string) => void;
  structureId: string;
};

export const DynamicList = ({ content, height, contentEditable, onTextChange, structureId }: ListRoot): JSX.Element => {

  const listType =
    content.list.listcontent[0].icon && content.list.listcontent[0].heading
      ? 'icon-heading'
      : 'bullet-numbered';
  return (
    <>
      <ol
        key={'ol_' + listType}
        style={{ height: height }}
        className={`ops-dynamic-list${listType === 'bullet-numbered' ? '-badge' : ''
          }`}
      >
        {content.list.listcontent.map((item: ListProps, index: number) => {
          return (
            <li key={'li_' + index.toString()}>
              {listType === 'bullet-numbered' ? <Badge type='tab' count={index + 1} supporting /> : null}
              <div className='numbered-group'>
                <div className='icon-heading'>{item.icon && (
                  <div
                    key={item.icon + '_' + index.toString()}
                    className="ops-dynamic-list-icon"
                  >
                    {item.icon === 'trendIcon' && <TrendingIcon />}
                    {item.icon === 'clockIcon' && <ClockIcon />}
                    {item.icon === 'waveIcon' && <WaveIcon />}
                  </div>
                )}
                  {item.heading && (
                    <div
                      key={item.heading + '_' + index.toString()}
                      className="ops-dynamic-list-header"
                    >
                      {item.heading}
                    </div>
                  )}
                </div>

                {item.text && (
                  <div
                    key={'_' + index.toString()}
                    className={`ops-dynamic-list-text${'--' + listType}`}
                    contentEditable={contentEditable}
                    data-id={content.content_id + '-' + item.details_id}
                    onBlur={(e: React.FocusEvent<HTMLDivElement>) => onTextChange(e, structureId)}
                    suppressContentEditableWarning={true}
                  >
                    <>{parse(item.text)}</>
                  </div>
                )}
                {item.image && (
                  <img
                    key={'_img' + index.toString()}
                    className="dynamic-list-image"
                    src={item?.image}
                    alt={item?.image}
                  />
                )}
              </div>
            </li>
          );
        })}
      </ol>
    </>
  );
};

export default DynamicList;
