export const lazyImport = (importMapRef: string) => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return System.import(importMapRef)
      .then()
      .catch(() => {
        return new Promise(() => console.log('failed to load tile'));
      });
  } catch (err) {
    console.log(err);
  }
};
