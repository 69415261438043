import React, { useEffect, useState } from 'react';
import { Modal, Button } from '@opsdti-global-component-library/amgen-design-system';
import './index.scss';
import { updateVideoImgOnS3 } from 'api/update-about-page-file';
import { useOktaAuth } from '@okta/okta-react';

interface ImgVideoUploadProps {
    opened: boolean;
    onImgVideoUploadModalClosed: () => void;
    contentUpdate: () => void;
    videoKey?: string;
    posterKey?: string;
    title?: string;
}

const ImgVideoUploadModal = (props: ImgVideoUploadProps) => {
    const { opened, onImgVideoUploadModalClosed, posterKey, videoKey, title, contentUpdate } = props;
    const [posterName, setPosterName] = useState('');
    const [videoName, setVideoName] = useState('');
    const [isLoading, setLoading] = useState({ video: false, poster: false });
    const [respMsg, setRespMsg] = useState('');
    const [selectedPoster, setSelectedPoster] = useState<any>(null);
    const [selectedVideo, setSelectedVideo] = useState<any>(null);
    const inputVideoRef = React.useRef<HTMLInputElement>(null);
    const inputposterRef = React.useRef<HTMLInputElement>(null);
    const { authState } = useOktaAuth();
    const [maxFileSize, setMaxFileSize] = useState('');

    const setVideo = (e: any) => {
        const selectedFile = e.target.files[0];
        const mediaSize = selectedFile?.size / 1024 / 1024;
        const mediaType = selectedFile?.type;
        console.log('Type--------', selectedFile, 'Size--------', selectedFile?.size / 1024 / 1024);

        if (mediaSize > 25) {
            console.log('Size--------', selectedFile?.size / 1024 / 1024, 'MB')
            setVideoName('');
            setMaxFileSize('Maximum size allowd is 25MB');
        } else if (!mediaType.includes('mp4')) {
            setVideoName('');
            setMaxFileSize('Only mp4 format is allowed');
        } else {
            setVideoName(selectedFile?.name);
            setSelectedVideo(selectedFile);
            setRespMsg('');
            setMaxFileSize('');
        }
    }

    const setPoster = (e: any) => {
        const selectedFile = e.target.files[0];

        const mediaSize = selectedFile?.size / 1024 / 1024;
        const mediaType = selectedFile?.type;

        if (mediaSize > 5) {
            setPosterName('');
            setMaxFileSize('Maximum size allowd is 5MB');
        } else if (!mediaType.includes('png') && !mediaType.includes('jpeg') && !mediaType.includes('jpg')) {
            setPosterName('');
            setMaxFileSize('Only jpg/png/jpeg format is allowed');
        } else {
            setPosterName(selectedFile?.name);
            setSelectedPoster(selectedFile);
            setRespMsg('');
            setMaxFileSize('');
        }
    }

    const handleVideoChoose = () => {
        if (inputVideoRef?.current) {
            inputVideoRef.current.click();
        }
    };

    const handleposterChoose = () => {
        if (inputposterRef?.current) {
            inputposterRef.current.click();
        }
    };

    const postVideoPoster = async (key: string | undefined, fileData: any, type: string) => {
        try {
            const payload = {
                file: fileData,
                key: key
            }
            setLoading({ video: type === 'video' ? true : false, poster: type === 'poster' ? true : false });
            const res = await updateVideoImgOnS3(authState?.accessToken?.accessToken, payload);
            if (res.data.status === "SUCCESS") {
                if (type === 'video') {
                    setRespMsg(videoName + ' has been uploaded successfully')
                    setVideoName('');
                    setSelectedVideo(null);
                    contentUpdate();
                }
                if (type === 'poster') {
                    setRespMsg(posterName + ' has been uploaded successfully')
                    setPosterName('');
                    setSelectedPoster(null);
                    contentUpdate();
                }
            } else {
                setRespMsg('Error while uploading, please try again')
                setPosterName('');
                setVideoName('');
                setSelectedPoster(null);
                setSelectedVideo(null);
            }
        } catch (error) {
            setRespMsg('Error while uploading, please try again')
            console.log(error);
        }
        setLoading({ video: false, poster: false })
    };


    useEffect(() => {
        if (!opened) {
            setPosterName('');
            setVideoName('');
            setSelectedPoster(null);
            setSelectedVideo(null);
            setRespMsg('');
        }
    }, [opened])

    return (
        <Modal
            className="video-upload-modal"
            open={opened}
            onCancel={onImgVideoUploadModalClosed}
            title={`Upload ${title}`}
        >
            {/*          <Modal.Header contentClassName="d-flex align-items-center" className='video-modal-header'>
                <Modal.Title title={`Upload ${title}`} className='video-modal-title' />
            </Modal.Header> */}
            <div className='video-upload-form'>
                {/* <h4>Please{` Select ${title}`}</h4> Redundant asking for choose file*/}
                {(respMsg !== '') && (
                    <div> {respMsg}</div>
                )}
                <div className="upload-btn-container">
                    {(title === 'Video or Poster') &&
                        <div className='upload-btn'>
                            <input
                                ref={inputVideoRef}
                                accept=".mp4"
                                onChange={setVideo}
                                type="file"
                                hidden
                            />
                            <Button type='secondary' className='choose-btn' text='Choose Video' onClick={handleVideoChoose}></Button>
                            {(selectedVideo) && (
                                <div className='d-flex'>
                                    <div className='selected-file-name'>
                                        {videoName}
                                    </div>
                                    <div className='d-flex'>
                                        <Button type='secondary' text='cancel' onClick={() => { setVideoName(''); setSelectedVideo(null); }}></Button>
                                        <Button type='primary' text='submit' disabled={videoName === ''} onClick={() => postVideoPoster(videoKey, selectedVideo, 'video')} loading={isLoading.video ? true : undefined}></Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                    <div className='upload-btn'>
                        <input
                            ref={inputposterRef}
                            accept="image/*"
                            onChange={setPoster}
                            type="file"
                            hidden
                        />
                        <Button type='secondary' className='choose-btn' text={`Choose Image`} onClick={handleposterChoose}></Button>
                        {(selectedPoster) && (
                            <div className='d-flex'>
                                <div className='selected-file-name'>
                                    {posterName}
                                </div>
                                <div className='d-flex'>
                                    <Button type='secondary' text='cancel' onClick={() => { setPosterName(''); setSelectedPoster(null); }}></Button>
                                    <Button type='primary' text='submit' disabled={posterName === ''} onClick={() => postVideoPoster(posterKey, selectedPoster, 'poster')} loading={isLoading.poster}></Button>
                                </div>
                            </div>
                        )}
                    </div>
                    <span className='footer error'>{maxFileSize}</span>
                </div>
            </div>
        </Modal>
    );
};

export default ImgVideoUploadModal;