import axios from 'axios';
const baseUrl: string = process.env.REACT_APP_ADMIN_API_LINK as string;

export const updateVideoImgOnS3 = async (oktaToken: string | undefined, payload: { file: FormData, key: string | undefined }) => {
    const finalUrl = `${baseUrl}/sensingAbout/uploadfile`;
    const res = await axios({
        method: 'POST',
        url: finalUrl,
        headers: {
            Authorization: `Bearer ${oktaToken}`,
            "Content-Type": "multipart/form-data",
        },
        data: payload
    });
    return res;
};