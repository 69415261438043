import React, { useEffect, useState } from 'react';
import './index.scss';
import { useOktaAuth } from '@okta/okta-react';
import { Modal, Button, PlusIcon, TrashIcon } from '@opsdti-global-component-library/amgen-design-system';
import { deleteValueCapture, insertUpdateValueCapture } from 'api/value-capture-page';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

interface ValueCaptureSlideProps {
    opened: boolean;
    valueCaptureUpdateModalClosed: () => void;
    valueCaptureData: [];
    refreshTable: () => void;
    pagesection: string;
    tableHeader: { Header: string, accessor: string }[];
    title: string;
    yearList: string[];

}

const ValueCaptureUpdateModal = (props: ValueCaptureSlideProps) => {
    const { opened, valueCaptureUpdateModalClosed, valueCaptureData, refreshTable, pagesection, tableHeader, title, yearList } = props;
    const { authState } = useOktaAuth();
    const [slideData, setSlideData] = useState<any>([]);
    const [isLoading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [addRowCount, setAddRowCount] = useState(0);
    const [initialData, setInitialData] = useState<any>([]);
    const [disableOnClick, setDisableOnClick] = useState(false);

    const updateSlideDescriptionEvent = (newRow: any, name: string, value: string, index: number) => {
        if (name === "metric_id") {
            const updatedData = slideData.map((row: any) => {
                if (row.id === newRow.id) {
                    row.metric_id = value;
                    return row;
                }
                else {
                    return row;
                }
            })
            setSlideData(updatedData)
        }

        if (name === "metric_value") {
            const updatedData = slideData.map((row: any) => {
                if (row.id === newRow.id) {
                    row.metric_value = value;
                    return row;
                }
                else {
                    return row;
                }
            })
            setSlideData(updatedData)
        }
        if (name === "year") {
            const updatedData = slideData.map((row: any) => {
                if (row.id === newRow.id) {
                    row.year = value;
                    row.QouterList = getQtrAndyear(value);
                    return row;
                }
                else {
                    return (row);
                }
            })
            setSlideData(updatedData)
        }
        if (name === "quarter") {
            const updatedData = slideData.map((row: any) => {
                if (row.id === newRow.id) {
                    row.quarter = value;
                    return row;
                }
                else {
                    return (row);
                }
            })
            setSlideData(updatedData)
        }
    }

    const addNewSlide = () => {
        const updatedData = slideData
        updatedData.unshift(
            {
                metric_id: "",
                id: 0 - addRowCount, //Will convert all negtive row to 0 for new row while saving. It's just for slidedata value updates in UI
                page_section: pagesection,
                metric_value: "",
                delivery_date: "None",
                year: "",
                quarter: "",
                QouterList: []
            })
        setSlideData([...updatedData])
        setAddRowCount(addRowCount + 1);
        setDisableOnClick(true);
    }

    const deleteSlide = async (row: any) => {
        try {
            if (row.id <= 0) {
                const updatedData = slideData.filter((data: any) => data.id !== row.id);
                setSlideData(updatedData);
                setDisableOnClick(false);
                return;
            }
            else {
                setLoading(true);
                const res = await deleteValueCapture(authState?.accessToken?.accessToken, row.id);
                if (res?.data?.status === 'SUCCESS') {
                    alert(`${title} row Deleted Successfully`)
                    refreshTable();
                    setInitialData([].concat(slideData));
                    setDisableOnClick(false);

                } else {
                    console.log(res);
                }
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    };

    const updateInsertSlide = async () => {
        try {
            setUpdateLoading(true);
            const payload = slideData.filter((obj: any) => obj.page_section === pagesection).map((data: any, indx: any) => {  // replacing id with 0 for new row required for backend
                return { ...data, ...{ index: indx, id: data.id < 0 ? 0 : data.id } };
            })
            payload.map((row: any) => { delete row.QouterList; return row; })
            const res = await insertUpdateValueCapture(authState?.accessToken?.accessToken, { "value_list": payload });
            if (res?.data?.status === 'SUCCESS') {
                refreshTable();
                setInitialData([].concat(slideData));
                setDisableOnClick(false);
                valueCaptureUpdateModalClosed();
            } else {
                console.log(res);
                alert('Error in updating data')
            }
        } catch (error) {
            console.log(error);
            alert('Insert/Update Failed')
        }
        setUpdateLoading(false)
    };

    const handleDragEnd = (result: any) => {
        if (!result.destination) return;
        const modalData = slideData.filter((content: any) => content.page_section === pagesection)
        const reorderedItem = modalData.splice(result.source.index, 1);
        modalData.splice(result.destination.index, 0, reorderedItem[0]);
        setSlideData(modalData)
    }

    const abortSave = () => {
        setSlideData([].concat(initialData));
        valueCaptureUpdateModalClosed();
        setDisableOnClick(false)
    }

    useEffect(() => {
        const dataArray: any = valueCaptureData.map((content: any) => {
            content.QouterList = getQtrAndyear(content.year)
            return content;
        });
        setSlideData([].concat(dataArray));
        setInitialData([].concat(dataArray));
    }, [valueCaptureData])

    const getQtrAndyear = (year: string) => {
        const dt = new Date();
        const month = dt.getMonth() + 1;
        const currentYr = dt.getFullYear();
        let returnVal: string[] = [];

        if (currentYr !== parseInt(year)) {
            returnVal = ['Q1', 'Q2', 'Q3', 'Q4'];
            return returnVal
        }
        //Below logic is to get the quarter based on the month, it opens up once that current quarter is over, since news letters are updated after that quarter is passed
        switch (true) {
            case month <= 3:
                //before april
                returnVal = ['Q1'];
                break;
            case month <= 6:
                //before july
                returnVal = ['Q1', 'Q2'];
                break;
            case month <= 9:
                //before october,
                returnVal = ['Q1', 'Q2', 'Q3'];
                break;
            case month <= 12:
                //before december,
                returnVal = ['Q1', 'Q2', 'Q3', 'Q4'];
                break;
        }
        return returnVal;
    };

    return (
        <Modal
            className="value-capture-slide-upload-modal"
            open={opened}
            onCancel={abortSave}
            title={title}
            width={'auto'}
        >
            <div className='value-capture-modal-content'>
                <div className="description-modal-body">
                    <div className="description-modal-table-container">
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided: any) => (
                                    <table className={pagesection !== "productivityTable" ? "slide-table" : "slide-table productivity-tbl"} {...provided.droppableProps} ref={provided.innerRef}>
                                        <thead>
                                            <tr >
                                                {tableHeader.map((data: any, indx: any) => {
                                                    return (
                                                        <th key={indx} >
                                                            {data.Header}
                                                        </th>
                                                    );
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <>
                                                {slideData.filter((content: any) => content.page_section === pagesection).map((data: any, indx: any) => {
                                                    return (
                                                        <Draggable key={'tr' + indx} draggableId={'tr' + indx} index={indx} >
                                                            {(provided: any) => (
                                                                <tr {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    ref={provided.innerRef} key={indx}>
                                                                    {tableHeader.map((colData: any, colIndx: any) => {
                                                                        if (colData.accessor === 'metric_value' || colData.accessor === 'metric_id') {
                                                                            return (
                                                                                <td key={indx + colIndx}>
                                                                                    <textarea
                                                                                        value={data[colData.accessor]}
                                                                                        autoFocus={false}
                                                                                        className={pagesection !== 'productivityTable' ? 'slide-text-box' : 'slide-text-box productivity-text-box'}
                                                                                        maxLength={pagesection === 'carousel' ? colData.maxLength : 1000}
                                                                                        name={colData.accessor}
                                                                                        onChange={e => updateSlideDescriptionEvent(data, e.target.name, e.target.value, indx)}
                                                                                        disabled={isLoading || updateLoading}
                                                                                    />
                                                                                </td>
                                                                            );
                                                                        }

                                                                        else if (colData.accessor === 'year') {
                                                                            return (
                                                                                <td key={indx + colIndx}>
                                                                                    <select
                                                                                        value={data[colData.accessor]}
                                                                                        autoFocus={false}
                                                                                        className='slide-text-box'
                                                                                        name={colData.accessor}
                                                                                        onChange={e => { updateSlideDescriptionEvent(data, e.target.name, e.target.value, indx) }}
                                                                                        disabled={isLoading || updateLoading}
                                                                                    >
                                                                                        <option key={indx} value={""}> Select </option>
                                                                                        {yearList.map((year: any, index: number) =>
                                                                                            <option key={index} value={year}> {year} </option>
                                                                                        )}
                                                                                    </select>
                                                                                </td>
                                                                            );

                                                                        }
                                                                        else if (colData.accessor === 'quarter') {
                                                                            return (
                                                                                <td key={indx + colIndx}>
                                                                                    <select
                                                                                        value={data[colData.accessor]}
                                                                                        autoFocus={false}
                                                                                        className='slide-text-box'
                                                                                        name={colData.accessor}
                                                                                        onChange={e => updateSlideDescriptionEvent(data, e.target.name, e.target.value, indx)}
                                                                                        disabled={isLoading || updateLoading}
                                                                                    >
                                                                                        <option key={indx} value={""}> Select </option>
                                                                                        {data.QouterList.map((qtr: any, index: number) =>
                                                                                            <option key={index} value={qtr}> {qtr} </option>
                                                                                        )}
                                                                                    </select>
                                                                                </td>
                                                                            );

                                                                        } else if (colData.accessor === 'delete') {
                                                                            return (
                                                                                <td key={indx + colIndx}>
                                                                                    <Button type='primary' className='slide-delete-btn' text='' onClick={() => deleteSlide(data)} disabled={isLoading} icon={<TrashIcon height={'20px'} width={'20px'} />} />
                                                                                </td>
                                                                            );
                                                                        }
                                                                    })}
                                                                </tr>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                            </>
                                            {provided.placeholder}
                                        </tbody>
                                    </table>
                                )}
                            </Droppable>

                        </DragDropContext>
                    </div>
                    <Button type='secondary' text='ADD ITEM' onClick={addNewSlide} icon={<PlusIcon height={'18px'} width={'18px'} style={{ 'marginTop': '-2px' }} />} className='slide-add-btn'></Button>
                </div>
            </div>
            <div className='value-capture-modal-footer'>
                <div className='btn-container'>
                    <Button type='secondary' text='CANCEL' className='slide-btn' onClick={abortSave}></Button>
                    <Button type='primary' text='SAVE CHANGES' className='slide-btn' onClick={updateInsertSlide} loading={updateLoading}></Button>
                </div>
            </div>
        </Modal >
    );
};

export default ValueCaptureUpdateModal;
