import { Modal } from '@gitlab-rtsensing/component-library';
import React from 'react';
import './index.scss';

interface ReportErrorModalProps {
  title?: string;
  opened: boolean;
  onReportErrorModalClosed: () => void;
  reportErrorSource: string;
}

const ReportErrorModal = (props: ReportErrorModalProps) => {
  const {
    title = '',
    opened,
    onReportErrorModalClosed,
    reportErrorSource,
  } = props;
  return (
    <Modal
      className="request-access-modal"
      opened={opened}
      onClose={onReportErrorModalClosed}
    >
      <Modal.Header contentClassName="d-flex align-items-center">
        <Modal.Title title={title} />
      </Modal.Header>
      <div className="request-access-iframe-wrapper">
        <div>
          <iframe
            seamless={true}
            title="Request Access Form"
            width="100%"
            height="100%"
            src={reportErrorSource}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ReportErrorModal;
