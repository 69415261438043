import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

//OKTA
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

// Custom Okta
import { RequiredAuth } from './secure-routes';
import { oktaAuthConfig } from '../config/okta-config';
import ReleaseNotesPage from 'pages/release-notes';
import { ReleaseNotesContextProvider } from 'contexts/release-notes-context';
import { ThemeProvider } from '@opsdti-global-component-library/amgen-design-system';
import { AuthPermissions } from 'types';
import AboutSensing from 'pages/about';
import ValueCapture from 'pages/about/value-capture';

interface AuthRoutesProps {
  componentToRender: React.ReactNode;
  route: string;
  themeService: unknown;
  permissions: AuthPermissions;
}

const AuthRoutes = (props: AuthRoutesProps): JSX.Element => {
  const { route, componentToRender } = props;

  /********** OKTA Authentication *********/
  const navigate = useNavigate();

  const oktaAuth = new OktaAuth(oktaAuthConfig);

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string,
  ) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  /********** OKTA Authentication *********/

  function getRoutes(route: string) {
    const paths = ['/about', '/about/value-capture', `${route}/notes`];
    if (route === '/about') {
      console.log('Props---', props);
      return (
        <>
          <Route element={<RequiredAuth />}>
            <Route
              path={paths[0]}
              element={<AboutSensing tilePermissions={props.permissions} />}
            ></Route>
          </Route>
          {props.permissions?.aboutValue && (
            <Route element={<RequiredAuth />}>
              <Route
                path={paths[1]}
                element={<ValueCapture tilePermissions={props.permissions} />}
              />
            </Route>
          )}
          <Route element={<RequiredAuth />}>
            <Route
              path={paths[2]}
              element={
                <ReleaseNotesContextProvider>
                  <ReleaseNotesPage />
                </ReleaseNotesContextProvider>
              }
            />
          </Route>
        </>
      );
    }

    return (
      <Route path={route} element={<RequiredAuth />}>
        <Route path="" element={componentToRender} />
      </Route>
    );
  }

  const themeService = undefined;

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ThemeProvider themeService={themeService}>
        <Routes>
          <Route path="/*" element={<RequiredAuth />}>
            <Route path="" element={componentToRender} />
          </Route>
          {getRoutes(route)}
          <Route path="/implicit/callback" element={<LoginCallback />} />
        </Routes>
      </ThemeProvider>
    </Security>
  );
};

export default AuthRoutes;
