import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-boundary';
import AuthRouter from 'routes/auth-routes';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthPermissions } from 'types';
const queryClient = new QueryClient();

interface AppProps {
  componentToRender: React.ReactNode;
  route: string;
  themeService: unknown;
  permissions: AuthPermissions;
}

const App = (props: AppProps): JSX.Element => {
  const { componentToRender, route, themeService, permissions } = props;

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AuthRouter componentToRender={componentToRender} route={route} themeService={themeService} permissions={permissions} />
        </QueryClientProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;
