import axios, { AxiosResponse } from 'axios';
interface configureDataProp {
  url: string;
  method: string;
  headers: object;
  data?: object;
}

export const fetchApi: (
  configureData: configureDataProp,
) => Promise<AxiosResponse> = async configureData => {
  return await axios({
    ...configureData,
  });
};
