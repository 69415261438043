import React from "react";

const TrendingIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.25 11C4.54837 11 4.83452 10.8815 5.0455 10.6705C5.25647 10.4595 5.375 10.1734 5.375 9.875C5.37866 9.83759 5.37866 9.79991 5.375 9.7625L7.4675 7.67H7.64H7.8125L9.02 8.8775C9.02 8.8775 9.02 8.915 9.02 8.9375C9.02 9.23587 9.13853 9.52202 9.34951 9.733C9.56048 9.94397 9.84663 10.0625 10.145 10.0625C10.4434 10.0625 10.7295 9.94397 10.9405 9.733C11.1515 9.52202 11.27 9.23587 11.27 8.9375V8.8775L14 6.125C14.2225 6.125 14.44 6.05902 14.625 5.9354C14.81 5.81179 14.9542 5.63609 15.0394 5.43052C15.1245 5.22495 15.1468 4.99875 15.1034 4.78052C15.06 4.56229 14.9528 4.36184 14.7955 4.2045C14.6382 4.04717 14.4377 3.94002 14.2195 3.89662C14.0012 3.85321 13.775 3.87549 13.5695 3.96064C13.3639 4.04578 13.1882 4.18998 13.0646 4.37498C12.941 4.55999 12.875 4.7775 12.875 5C12.8713 5.03741 12.8713 5.07509 12.875 5.1125L10.1675 7.82H10.0475L8.75 6.5C8.75 6.20163 8.63147 5.91548 8.4205 5.7045C8.20952 5.49353 7.92337 5.375 7.625 5.375C7.32663 5.375 7.04048 5.49353 6.8295 5.7045C6.61853 5.91548 6.5 6.20163 6.5 6.5L4.25 8.75C3.95163 8.75 3.66548 8.86853 3.4545 9.0795C3.24353 9.29048 3.125 9.57663 3.125 9.875C3.125 10.1734 3.24353 10.4595 3.4545 10.6705C3.66548 10.8815 3.95163 11 4.25 11V11ZM14.375 14H1.625V1.25C1.625 1.05109 1.54598 0.860322 1.40533 0.71967C1.26468 0.579018 1.07391 0.5 0.875 0.5C0.676088 0.5 0.485322 0.579018 0.34467 0.71967C0.204018 0.860322 0.125 1.05109 0.125 1.25V14.75C0.125 14.9489 0.204018 15.1397 0.34467 15.2803C0.485322 15.421 0.676088 15.5 0.875 15.5H14.375C14.5739 15.5 14.7647 15.421 14.9053 15.2803C15.046 15.1397 15.125 14.9489 15.125 14.75C15.125 14.5511 15.046 14.3603 14.9053 14.2197C14.7647 14.079 14.5739 14 14.375 14Z" fill="#0063C3"/>
</svg>
    )
}

export default TrendingIcon;