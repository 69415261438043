import React, { useEffect } from 'react';
import './index.scss';
import { ExternalLinkIcon } from '@gitlab-rtsensing/component-library';
import { NewsLetterList } from '../../../pages/about/about.d';
import { Tabs } from "@opsdti-global-component-library/amgen-design-system";

type List = {
  list: NewsLetterList[]
}

const NewsLetters = (props: List): JSX.Element => {
  const [formattedData, setFormattedData] = React.useState<any>([]);
  useEffect(() => {
    document.querySelectorAll('.newsletter-item')[0]?.classList.add('selected');

    const allYears = props.list.map((yr: any) => yr.title.match(/\b(20)\d{2}\b/g)[0]); //Extracting year from title
    const uniqueYears = allYears.filter((year, index, self) => self.indexOf(year) === index); //Removing duplicates
    uniqueYears.sort((a, b) => b - a).slice(0, 3); //Sorting in descending order and taking top 3 years

    const newsLetterData = uniqueYears.map((yr: any) => {
      return {
        year: yr,
        data: props.list.filter((obj: any) => obj.title.includes(yr))
      }
    });

    const tabData = newsLetterData.map((obj: any, index: any) => {
      return {
        key: index,
        label: obj.year,
        children: obj.data.map((data: any, indx: any) => {
          return (
            <div key={indx} className='newsletter-section-tabs'>
              <div className='section-header'>
                <span className='section-heading'>{data.description}</span>
                <span className="newsletter-item-icon" onClick={() => window.open(data.link, '_blank')}>
                  <ExternalLinkIcon
                    fill="var(--ops-gray-500)"
                    height={18}
                    width={18}
                  />
                </span>
              </div>
              <ul>{data.highlight.map((text: any, textIndx: any) => {
                return <li key={textIndx}>{text.text}</li>
              })}</ul>
            </div>
          )
        }, [])
      }
    });

    setFormattedData(tabData);
  }, []);

  return (

    <div className="newsletter-container">
      <Tabs items={formattedData} />
    </div>
  )
}

export default NewsLetters;
