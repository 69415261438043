import React from 'react';
import { useStyles } from './styles';
import bannerImage from '../../assets/images/banner-homepage.png';

export const BannerImage = () => {
  const { classes } = useStyles();
  return (
    <img
      alt="Homepage Banner"
      className={classes.bannerImage}
      src={`${process.env.REACT_APP_HOST}/${bannerImage}`}
    />
  );
};
