import React from 'react';
import tag2 from '../../assets/images/tag2-0.png';
import headerImage from '../../assets/images/header-image.png';

interface HeaderProps {
    page: string;
}

const Header: React.FC<HeaderProps> = ({ page }) => {
    const tagUrl = process.env.REACT_APP_HOST + '/' + tag2;
    const headerImageUrl = process.env.REACT_APP_HOST + '/' + headerImage;

    return (
        <div
            className="about-page-grid-item about-page-grid-item--banner"
            style={{
                background: `url(${headerImageUrl}) no-repeat top center / cover`,
            }}
        >
            {page === 'about-page' ?
                <><p className="ops-font-inter about-page-logo-text about-page-logo-text--large">
                    About Sensing{' '}
                    <span className='tag-style'>
                        <img src={tagUrl} alt="2.0" />
                    </span>
                </p>
                    <p className="ops-font-inter about-page-logo-text about-page-logo-text--small">
                        Your guide to the future of decision-making
                    </p></> :
                <>
                    <p className="ops-font-inter about-page-logo-text about-page-logo-text--large">
                        Sensing Value Capture{' '}
                    </p>
                    <p className="ops-font-inter about-page-logo-text about-page-logo-text--small">
                        Sharing the value sensing has created
                    </p>
                </>
            }

        </div>
    );
};


export default Header;