import { useOktaAuth } from '@okta/okta-react';
import { getPublishedReleaseNotes } from 'api/admin-api-release-notes';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

interface APIResponse {
  release_note_id: number;
  release_version_number: string;
  release_version_date: string;
  workstream: string;
  notes: string[];
}

export type Notes = {
  about?: [];
  admin?: string[];
  analytics?: string[];
  brand?: string[];
  external?: string[];
  finance?: string[];
  home?: string[];
  people?: string[];
  pipeline?: string[];
  prioritizedAgenda?: string[];
  product?: string[];
  supply?: string[];
  [name: string]: string[] | undefined;
};

export type Sublist = {
  sublist: {
    date: string;
    version: string;
    items: Notes;
  };
};

interface ReleaseNotesContextState {
  latestNotes: Sublist;
  allNotes: Sublist[];
  isLoading: boolean;
}

const ReleaseNotesContext = createContext<ReleaseNotesContextState | undefined>(
  undefined,
);

export const useReleaseNotesContext = (): ReleaseNotesContextState => {
  const context = useContext(ReleaseNotesContext);
  if (!context) {
    throw new Error(
      'useReleaseNotesContext must be used within ReleaseNotesContextProvider',
    );
  }
  return context;
};

interface ReleaseNotesContextProviderProps {
  children: React.ReactNode;
}

export const ReleaseNotesContextProvider: React.FC<
  ReleaseNotesContextProviderProps
> = ({ children }) => {
  const { authState } = useOktaAuth();
  const { data: res, isLoading } = useQuery({
    queryFn: () =>
      getPublishedReleaseNotes({
        token: authState?.accessToken?.accessToken,
      }),
  });

  const [allNotes, setAllNotes] = useState<Sublist[]>([]);
  const [latestNotes, setLatestNotes] = useState<Sublist>({
    sublist: {
      date: '',
      version: '',
      items: {},
    },
  });

  useEffect(() => {
    if (!res?.data) {
      return;
    }

    let { data: notes } = res;
    notes = convertResponseToSublist(notes);
    if (notes.length) {
      setAllNotes(notes);
      setLatestNotes(notes[0]);
    }
  }, [res]);

  return (
    <ReleaseNotesContext.Provider value={{ latestNotes, allNotes, isLoading }}>
      {children}
    </ReleaseNotesContext.Provider>
  );
};

function convertResponseToSublist(response: APIResponse[]): Sublist[] {
  // Create a Map to consolidate the API responses
  const consolidatedMap = new Map<string, Sublist>();

  // Process each response
  response.forEach(item => {
    // Create a key for the Map
    const key = `${item.release_version_number}-${item.release_version_date}`;

    // Get the existing sublist for this key, or create a new one if it doesn't exist
    let sublist = consolidatedMap.get(key);
    if (!sublist) {
      sublist = {
        sublist: {
          date: item.release_version_date,
          version: item.release_version_number,
          items: {},
        },
      };
      consolidatedMap.set(key, sublist);
    }

    // Process each note in the response
    item.notes.forEach(note => {
      if (sublist) {
        // Ensure sublist is defined
        // Get the existing notes for this workstream, or create a new array if it doesn't exist
        let notes = sublist.sublist.items[item.workstream];
        if (!notes) {
          notes = [];
          sublist.sublist.items[item.workstream] = notes;
        }

        // Add the note to the array
        notes.push(note);
      }
    });
  });

  // Convert the Map to an array and return it
  return Array.from(consolidatedMap.values());
}
