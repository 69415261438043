import React, { useState, useEffect } from 'react';
import ImgVideoUploadModal from '../image-video-modal';
import { Button } from '@opsdti-global-component-library/amgen-design-system';
export type VideoProps = {
  width?: string;
  title?: string;
  src: string;
  poster?: string;
  posterKey: string;
  videoKey?: string;
  contentEditable?: boolean;
  contentUpdate: () => void;
};

export const VideoPlayer = (props: VideoProps): JSX.Element => {
  const { title, src, poster, contentEditable, posterKey, videoKey, contentUpdate } = props;
  const [videoUploadModalOpen, setvideoUploadModalOpen] = useState(false);
  const [videoUploaded, setvideoUploaded] = useState(false);

  useEffect(() => {
    if (videoUploaded) {
      contentUpdate();
    }
  }, [videoUploaded])

  return (
    <>
      <div className="ops-video-player">
        <video src={src} poster={poster ? poster : ''} controls>
          {title}
        </video>
        {contentEditable ? <Button type="primary" className='upload-content' onClick={() => setvideoUploadModalOpen(true)} text="upload"></Button> : <></>}
      </div>
      <>{videoKey && posterKey ? <ImgVideoUploadModal opened={videoUploadModalOpen} onImgVideoUploadModalClosed={() => setvideoUploadModalOpen(false)} contentUpdate={() => setvideoUploaded(true)} videoKey={videoKey} posterKey={posterKey} title='Video or Poster' /> : null}</>
    </>
  );
};

export default VideoPlayer;
