import React from 'react';
import {
  Flex,
  Title,
  Breadcrumb,
  BreadcrumbProps,
} from '@opsdti-global-component-library/amgen-design-system';
import { useStyles } from './styles';

type BannerProps = {
  /** The title of the banner */
  title: string;
  /** The subtitle of the banner */
  subtitle?: string;
  /** Extra slot to add addition items */
  slot?: React.ReactNode;
  /** Breadcrumb component for navigation */
  breadcrumb?: BreadcrumbProps;
  /** Background Image component */
  image?: React.ReactNode;
  /** Background Image component */
  legacy?: boolean;
};

export const Banner = ({
  breadcrumb,
  title,
  subtitle,
  slot,
  image,
  legacy,
}: BannerProps) => {
  const { classes, cx } = useStyles({ legacy });
  return (
    <Flex className={classes.bannerContainer} data-testid="banner">
      <div className={classes.imageContainer}>{image}</div>
      <Flex
        className={cx('ds-component', classes.banner)}
        flex="1 1"
        gap={20}
        vertical
      >
        <Breadcrumb {...breadcrumb} className={classes.breadcrumb} />
        <Flex vertical>
          <Flex gap={8} align="center">
            <Title level={1} className={classes.title}>
              {title}
            </Title>
            {slot}
          </Flex>
          {subtitle && (
            <Title level={3} className={classes.subtitle}>
              {subtitle}
            </Title>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export { Badge } from './badge';
export { BannerImage } from './image';
