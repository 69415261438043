export const Value_Capture_Slide_Table_Header = [
    {
        Header: 'Value(6 char max)',
        accessor: 'metric_value',
        maxLength: 6,
    },
    {
        Header: 'Description(50 char max)',
        accessor: 'metric_id',
        maxLength: 50,
    },
    {
        Header: 'Delete',
        accessor: 'delete',
    }
];
export const Productivity_Table_Header = [
    {
        Header: 'Measure/ Outcome',
        accessor: 'metric_value',
        maxLength: 1000
    },
    {
        Header: 'Key Driver',
        accessor: 'metric_id',
        maxLength: 1000
    },
    {
        Header: 'Year',
        accessor: 'year',
    },
    {
        Header: 'Quarter',
        accessor: 'quarter',
    },
    {
        Header: 'Delete',
        accessor: 'delete',
    }
];
export const KeyQoute_Table_Header = [
    {
        Header: 'Name',
        accessor: 'metric_value',
        maxLength: 1000
    },
    {
        Header: 'Quotes',
        accessor: 'metric_id',
        maxLength: 1000
    },
    {
        Header: 'Delete',
        accessor: 'delete',
    }
];
export const KeyHighlight_Table_Header = [
    {
        Header: 'Description',
        accessor: 'highlight',
        maxLength: 1000
    },
    {
        Header: 'Delete',
        accessor: 'delete',
    }
];
