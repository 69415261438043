import {
  Loader,
} from '@gitlab-rtsensing/component-library';
import { Button, Card } from '@opsdti-global-component-library/amgen-design-system';
import React, { Suspense } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { convertToTitleCase } from 'utils/string-utils';
import { useReleaseNotesPageNavManager } from 'hooks/use-nav-manager';
import { dateFormatter } from 'utils/date-formatter';
import {
  Notes,
  Sublist,
  useReleaseNotesContext,
} from 'contexts/release-notes-context';

export default function ReleaseNotesPage() {
  const { isLoading, allNotes: allReleaseNotes } = useReleaseNotesContext();
  const navigate = useNavigate();

  function createLinks(releaseNotes: Sublist[]) {
    const links = {
      jumpToSections: [
        {
          sectionLabel: 'Release Notes by Version',
          links: releaseNotes.map((releaseNote: Sublist) => {
            return {
              label: `v${releaseNote.sublist.version} - ${dateFormatter(
                releaseNote.sublist.date,
              )}`,
              url: `#v${releaseNote.sublist.version}`,
              disabled: false,
            };
          }),
        },
      ],
    };

    return links;
  }

  useReleaseNotesPageNavManager(createLinks(allReleaseNotes));

  function renderReleaseNote(releaseNote: Sublist) {
    const keys = Object.keys(releaseNote.sublist.items);
    return keys.map((key, index) => {
      return (
        <li key={'li_' + index.toString()}>
          <div className="ops-dynamic-sublist-container">
            <div
              key={key + '_' + index.toString()}
              className="ops-dynamic-list-header"
            >
              {convertToTitleCase(key)}
            </div>
            <ul className="ops-dynamic-sublist">
              {releaseNote.sublist.items[key as keyof Notes]?.map(
                (subitem: string, index: number) => {
                  return (
                    <li
                      key={'sublist_li' + index.toString()}
                      className="ops-dynamic-sublist-item"
                    >
                      <p className="release-notes-page-text">{subitem}</p>
                    </li>
                  );
                },
              )}
            </ul>
          </div>
        </li>
      );
    });
  }

  function renderAllReleaseNotes(releaseNotes: Sublist[]) {
    return (
      <>
        {releaseNotes.map(releaseNote => {
          return (
            <div
              id={`v${releaseNote.sublist.version}`}
              key={`v${releaseNote.sublist.version}`}
            >
              <Card
                key={`v${releaseNote.sublist.version}`}
                className="ops-metric-card release-note-page-section"
                title={`v${releaseNote.sublist.version} released on ${releaseNote.sublist.date}`}
              >

                <ul
                  key={'ul_unordered-list'}
                  style={{ height: 'auto' }}
                  className="ops-dynamic-list-bulleted"
                >
                  {renderReleaseNote(releaseNote)}
                </ul>

              </Card>
            </div>
          );
        })}
      </>
    );
  }

  return (
    <div className="home-page-container release-notes-page">
      {isLoading ? (
        <Loader />
      ) : (
        <div id="releasenotes" key="releasenotes">
          <Suspense key="releasenotes" fallback={<div>Loading...</div>}>
            <div className="release-notes-button-container">
              <Button
                className="release-notes-page-button"
                onClick={() => {
                  navigate(-1);
                }}
                type="secondary"
                text="RETURN"
              />
            </div>
            <ul
              key={'ul_unordered-list'}
              style={{ height: 'auto' }}
              className="ops-dynamic-list-bulleted"
            >
              {renderAllReleaseNotes(allReleaseNotes)}
            </ul>
          </Suspense>
        </div>
      )}
    </div>
  );
}
