import axios from 'axios';
const baseUrl: string = process.env.REACT_APP_ADMIN_API_LINK as string;

export const getAboutPageData = async (oktaToken: string | undefined) => {
    const finalUrl = `${baseUrl}/sensingAbout/getSensingAboutData`;
    const res = await axios({
        method: 'get',
        url: finalUrl,
        headers: {
            Authorization: `Bearer ${oktaToken}`,
        },
    });
    return res;
};

export const postAboutPageData = async (oktaToken: string | undefined, payload: unknown) => {
    const finalUrl = `${baseUrl}/sensingAbout/updateSensingAboutText`;
    const res = await axios({
        method: 'POST',
        url: finalUrl,
        headers: {
            Authorization: `Bearer ${oktaToken}`,
        },
        data: payload
    });
    return res;
};

export const getAboutPageImg = async (oktaToken: string | undefined, imgKey: string) => {
    const finalUrl = `${baseUrl}/sensingAbout/aboutLink?key=${imgKey}`;
    const res = await axios({
        method: 'get',
        url: finalUrl,
        headers: {
            Authorization: `Bearer ${oktaToken}`,
        },
    });
    return res;
};