import { tss } from '@opsdti-global-component-library/amgen-design-system';

// WILL BE REPLACED WITH VARIABLES FROM DESIGN SYSTEM EVENTUALLY
const sizes = {
  xs: 480,
  sm: 768,
  md: 1024,
  lg: 1440,
  xl: 1768,
  xxl: Infinity,
} as const;

type Size = keyof typeof sizes;

// WILL BE REPLACED WITH VARIABLES FROM DESIGN SYSTEM EVENTUALLY
export const breakpoints = {
  ...sizes,
  down: (size: Size) => `@media (max-width: ${sizes[size]}px)`,
  up: (size: Size) => `@media (min-width: ${sizes[size] + 1}px)`,
  between: (minSize: Size, maxSize: Size) =>
    `@media (min-width: ${sizes[minSize] + 1}px) and (max-width: ${
      sizes[maxSize]
    }px)`,
};

const colors = {
  white: '#ffffff',
};

export const useStyles = tss
  .withParams<{ legacy?: boolean }>()
  .create(({ legacy }) => ({
    bannerContainer: {
      position: 'relative',
      width: '100%',
    },
    imageContainer: {
      height: '100%',
      overflow: 'hidden',
      position: 'absolute',
      width: '100%',
      zIndex: 0,
    },
    banner: {
      background:
        'linear-gradient(90deg, rgba(9, 55, 105, 0.85) 39.5%, rgba(14, 88, 168, 0.3) 52%, rgba(18, 108, 207, 0.1) 100%)',
      width: '100%',
      zIndex: 1,
      padding: `24px 32px 24px ${legacy ? 60 : 24}px`,
      [breakpoints.down('lg')]: {
        padding: `24px 32px 24px ${legacy ? 60 : 24}px`,
      },
      [breakpoints.down('md')]: {
        padding: `20px 20px 20px ${legacy ? 40 : 20}px`,
      },
      [breakpoints.down('sm')]: {
        padding: `20px 20px 20px ${legacy ? 40 : 20}px`,
      },
      [breakpoints.down('xs')]: {
        padding: 16,
      },
    },
    breadcrumb: {
      '&.ds-component': {
        '.ds-breadcrumb-separator': {
          color: colors.white,
        },
        '.ds-breadcrumb-link': {
          color: colors.white,
          a: {
            color: colors.white,
            '&:hover': {
              color: 'rgba(255, 255, 255, 0.2)',
              background: 'transparent',
            },
          },
        },
      },
    },
    title: {
      '&.ds-component': {
        color: colors.white,
        // Media queries for font size.
        // This should be removed in the future when DS has media queries for font size
        [breakpoints.down('md')]: {
          fontSize: 18,
        },
        [breakpoints.down('sm')]: {
          fontSize: 18,
        },
        [breakpoints.down('xs')]: {
          fontSize: 18,
        },
      },
    },
    subtitle: {
      '&.ds-component': { color: colors.white },
    },
    badge: {
      background: 'linear-gradient(180deg, #0063C3 0%, #469BDB 100%)',
      border: '1px solid white',
      borderRadius: 4,
      color: colors.white,
      fontFamily: 'Inter',
      fontSize: 12,
      fontWeight: 700,
      padding: '2px 4px',
      wordWrap: 'break-word',
    },
    bannerImage: {
      width: '100%',
      height: '100%',
      objectPosition: 'right',
      objectFit: 'cover',
    },
  }));
