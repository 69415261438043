import { Button, Container } from '@gitlab-rtsensing/component-library';
import ReportErrorModal from 'components/report-error-modal';
import React, { useState } from 'react';
import './index.scss';

type ErrorProps = {
  error: Error;
};

function ErrorFallback(props: ErrorProps) {
  const { error } = props;

  const [reportErrorModalOpen, setReportErrorModalOpen] = useState(false);
  return (
    <Container>
      <div className="error-boundary-inner-content">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="105"
          height="105"
          fill="currentColor"
          className="error-boundary-svg"
          viewBox="0 0 16 16"
        >
          {' '}
          <path
            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
            fill="#FF0033"
          ></path>
          <path
            d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"
            fill="#FF0033"
          ></path>
        </svg>
        <h2 className="error-boundary-title">Something Went Wrong</h2>
        <p className="error-boundary-message">
          We apolgize, an error has occured.
          <span className="error-boundary-error-code">
            ({error.toString()})
          </span>
        </p>
        <div className="error-boundary-button-container">
          <Button
            label="Report Error"
            type="secondary"
            onClick={() => setReportErrorModalOpen(true)}
            className="error-boundary-left-button"
          />
          <Button
            label="Try Again"
            type="secondary"
            onClick={() => window.location.reload()}
            className="error-boundary-right-button"
          />
        </div>
      </div>

      <ReportErrorModal
        reportErrorSource="https://app.smartsheet.com/b/form/2507ea35bef342cda8325c4f01023cea"
        opened={reportErrorModalOpen}
        onReportErrorModalClosed={() => setReportErrorModalOpen(false)}
      />
    </Container>
  );
}

export default ErrorFallback;
