import { fetchApi } from './fetch-api';
const baseUrl: string = process.env.REACT_APP_API_URL as string;
interface IKeyEventsAndPrioritizedFetch {
  token: string | undefined;
  parameter: string | undefined;
}
export const postKeyEventsAndPrioritized = async (
  KeyEventsAndPrioritizedFetch: IKeyEventsAndPrioritizedFetch,
) => {
  const response = await fetchApi({
    headers: {
      authorization: `Bearer ${KeyEventsAndPrioritizedFetch.token}`,
    },
    method: 'POST',
    url: `${baseUrl}/image_render/render_tile_image?tile_name=${KeyEventsAndPrioritizedFetch.parameter}`,
  });
  return response;
};
